:local(.globalWrapper) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 10rem;
    background-color: white;
}

:local(.copyWrapper) {
    margin: 10rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

:local(.copyWrapper h1) {
    font-family: MonsterMedium;
    font-size: 30px;
    text-align: center;
}

:local(.copyWrapper p) {
    font-size: 2rem;
    text-align: center;
}

:local(.coloredSpacer) {
    margin-top: 2rem;
    width: 6rem;
    height: 0.5rem;
    background: #66FCF1;
}

:local(.videoSegments) {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
}

@media screen and (max-width: 750px) {
    :local(.videoSegments) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
}