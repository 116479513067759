.top-nav-secondary {
    width: 25vw;
    box-shadow: -0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.473);
    position: fixed;
    right: 0;
    z-index: 9999;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    background: white;
}

:local(.logoWrapper) {
    width: 100%;
    height: 17rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    border-bottom-color: #800000;
}

:local(.logo) {
    margin-top: 1rem;
    margin-left: 1rem;
    height: 14rem;
}

:local(.scrollable) {
    flex: 1;
    overflow: auto;
    width: 100%;
    background: #800000;
}

:local(.flexWrapper) {
    display: flex;
    width: 100%;
    flex-direction: column;
}

:local(.optionsList) {
    margin-top: 20%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

:local(.option) {
    cursor: pointer;
    padding: 1rem 0;
    width: 90%;
    text-align: left;
    padding-left: 10%;
    color: white !important;
    font-size: 3rem;
}

:local(.socialText) {
    margin-top: 6rem;
    color: #66FCF1;
    font-family: MonsterBold;
}

:local(.socialMediaIcons) {
    display: flex;
    width: 100%;
    margin: 3rem 0;
    justify-content: center;
    align-items: center;
}

:local(.socialMediaIcons span) {
    margin: 0 2rem
}

@media screen and (max-width:950px) {
    .top-nav-secondary {
        width: 70vw;
    }
    :local(.optionsList) {
        align-items: center;
    }
    :local(.option) {
        text-align: center;
        padding: 1.5rem 0;
        padding-left: 0;
        font-size: 4rem;
    }
    :local(.socialText) {
        font-size: 2rem;
    }
}