html {
    font-size: 10px;
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 8px;
    }
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 7px;
    }
}

@media screen and (max-width: 850px) {
    html {
        font-size: 6px;
    }
}

@media screen and (max-width: 500px) {
    html {
        font-size: 5px;
    }
}
.top-nav-secondary {
    width: 25vw;
    box-shadow: -0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.473);
    position: fixed;
    right: 0;
    z-index: 9999;
    height: 100vh;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: white;
}

._3RYY5OOCz3JpxmvL6xK1g_ {
    width: 100%;
    height: 17rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    border-bottom-color: #800000;
}

._2UnBi55jxsEt4MhIdW24JY {
    margin-top: 1rem;
    margin-left: 1rem;
    height: 14rem;
}

._1bOc2u7HSgn9wzCxIt0B1B {
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow: auto;
    width: 100%;
    background: #800000;
}

.dN42gUDiVamEGZp6cCFVG {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
}

._3WgqlWLTDk2m4kMsoeSPGt {
    margin-top: 20%;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
}

.FFhneVctLQC2VSYCAAM3a {
    cursor: pointer;
    padding: 1rem 0;
    width: 90%;
    text-align: left;
    padding-left: 10%;
    color: white !important;
    font-size: 3rem;
}

._3pooetm8d5zffN0vsLr9EP {
    margin-top: 6rem;
    color: #66FCF1;
    font-family: MonsterBold;
}

.GrzuHgCshm5e6gmH5ZFj7 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin: 3rem 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.GrzuHgCshm5e6gmH5ZFj7 span {
    margin: 0 2rem
}

@media screen and (max-width:950px) {
    .top-nav-secondary {
        width: 70vw;
    }
    ._3WgqlWLTDk2m4kMsoeSPGt {
        -webkit-align-items: center;
                align-items: center;
    }
    .FFhneVctLQC2VSYCAAM3a {
        text-align: center;
        padding: 1.5rem 0;
        padding-left: 0;
        font-size: 4rem;
    }
    ._3pooetm8d5zffN0vsLr9EP {
        font-size: 2rem;
    }
}
._37c3gNb73gRAm64m7WTEst {
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: white;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

._3x7cMf59JDgd75K509El6- {
    margin-top: 1.5em;
    background: white;
    border-radius: 1em;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.664);
    width: 30vw;
}

.ZAoY-0HwKR8iLM-VhbahY {
    color: #800000;
    font-size: 2.4rem;
    font-style: italic;
    font-weight: 700;
}

._37c3gNb73gRAm64m7WTEst h1 {
    color: white;
    font-weight: 700;
    font-size: 5rem;
    margin-bottom: 2rem;
}

.Ye6JasebPCSoKojrfOt9d {
    display: -webkit-flex;
    display: flex;
    height: 50vw;
    max-height: 90vh;
    width: 100vw;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* background: rgb(20, 20, 20); */
    background:  #800000;
    box-shadow: 0 0.5rem 2.5rem 0 black;
}

._3EF1ekDR-QWJ1E5KjfPZP5 {
    font-size: 20rem;
    color: rgb(161, 161, 161);
}

._2SEBjMA2Be94qY6KQZdWdQ {
    width: 100%;
    height: 100%;
}
._1timm1-HxJ-msllBSB9eyb {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    padding-bottom: 10rem;
    background-color: white;
}

.L1HROfa_8HhRA2NLxBpx0 {
    margin: 10rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
}

.L1HROfa_8HhRA2NLxBpx0 h1 {
    font-family: MonsterMedium;
    font-size: 30px;
    text-align: center;
}

.L1HROfa_8HhRA2NLxBpx0 p {
    font-size: 2rem;
    text-align: center;
}

._10to9k98qWPYSWTE9NPi1m {
    margin-top: 2rem;
    width: 6rem;
    height: 0.5rem;
    background: #66FCF1;
}

._2pyccZXpmzv1nG_GxYtH {
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
}

@media screen and (max-width: 750px) {
    ._2pyccZXpmzv1nG_GxYtH {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        -webkit-align-items: center;
                align-items: center;
    }
}
._3XjQ78okvTcKQje7K1hFsW {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 30%;
    margin: 0px 1.5%;
    margin-bottom: 40px;
    box-sizing: border-box;
}

._1YShvVamse1GZYjYhj_jxR {
    background: #800000;
    padding: 0.8rem 2rem;
    color: white;
    position: absolute;
    font-size: 1.5rem;
    top: 1rem;
    left: 1rem;
    border-radius: 5px;
}

._2uB4sMoEDGvT7Xs9aUdH-3 {
    background: rgba(0, 0, 0, 0.562);
    border-radius: 10px;
    padding: 0.5rem 2rem;
    cursor: pointer;
}

._3UTPXqK0be-m1uZakoD5ri {
    cursor: pointer;
    color: rgb(127, 127, 127);
    font-size: 5rem;
}

@media screen and (max-width: 750px) {
    ._3XjQ78okvTcKQje7K1hFsW {
        width: 90%;
    }
    ._1YShvVamse1GZYjYhj_jxR {
        font-size: 2.5rem;
    }
    ._2uB4sMoEDGvT7Xs9aUdH-3 {
        border-radius: 5px;
        padding: 3rem 6rem;
    }
    ._3UTPXqK0be-m1uZakoD5ri {
        font-size: 10rem;
    }
}

@media screen and (max-width: 450px) {
    ._2uB4sMoEDGvT7Xs9aUdH-3 {
        border-radius: 5px;
        padding: 0.8rem 3rem;
    }
    ._3UTPXqK0be-m1uZakoD5ri {
        color: white;
        font-size: 8rem;
    }
}
._303mcrGf1Ud3gpDfXL1qyk {
    position: relative;
    height: 90vh;
    min-height: 130rem;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    background-blend-mode: multiply;
    background-color: black; 
    overflow: hidden;
}

.yX_6O3vHVfJ12ElwwU0eT {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: black; 
}

._34zBB5bDMsjvtObZbnhCNQ {
    min-height: 130rem;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

._3aqvEU0dYoL_f6PnJRkVqv {
    width: 100vw;
    background: black;
}

._1vGZ0emNECf5uLsmTUXIoS {
    position: relative;
    z-index: 2;
    width: 60%;
    margin: 0 5em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

._25Q1UoR4m19qKjpPXmOX_6 {
    margin-bottom: 3.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    text-align: left;
    font-size: 3.8rem;
    line-height: 5.2rem;
    font-weight: 900;
    color: rgb(192, 14, 14);
}

._1gJlQtOM9YJ_dPbhT1ml9R {
    margin-bottom: 3.5rem;
    display: -webkit-flex;
    display: flex;
    font-size: 2.8rem;
    line-height: 5.2rem;
    font-weight: 700;
    color: white;
}

._1WIEHKxk2m0sDRibkHba7v {
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
    line-height: 3.2rem;
    letter-spacing: 0rem;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-align: left;
}


.QDL8fwEugrJCpyo9r4fFo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* background: blue; */
}

._3Vsml8eT8d247olz_UWHFC {
   font-style: italic;
   font-weight: bolder;
   padding-right: 0.2rem;
}

._1m_qIfSurTTNa4qUWWuNtd {
    font-size: 2rem;
    font-weight: 400;
}

._1u-KYVrYn8mE90eB6yoBpG {
    height: 18em;
  }

#_2-sCXdMIfHJbr5L8z98rQC {
    cursor: pointer;
    margin-bottom: 1.5rem;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.8rem;
    border-style: solid;
    height: 6.5rem;
    padding: 0rem 2rem;
    border-width: 0.2rem;
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    outline: none;
    background-color: #0f0f0f;
}

._1VdAlOCCXyb29kOXPdXLLZ {
    color: #ffffff;
}

#_2-sCXdMIfHJbr5L8z98rQC:hover {
    border-color: rgb(8, 165, 154);
    color: rgb(255, 255, 255);
    background-color: #0f0f0f;
}

/* @media screen and (min-width: 1301px) {
    :local(.mainWrapper){
        min-height: 80rem;
    }
} */

@media screen and (min-width: 1200px) {
    ._303mcrGf1Ud3gpDfXL1qyk {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        min-height: 120rem;
    }
    ._1vGZ0emNECf5uLsmTUXIoS {
        position: relative;
        z-index: 2;
        width: 55%;
        margin: 0 5em;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media screen and (max-width: 1199px) {

    ._303mcrGf1Ud3gpDfXL1qyk {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        min-height: 130rem;
        
    }
    ._1vGZ0emNECf5uLsmTUXIoS {
        position: relative;
        z-index: 2;
        width: 80%;
        margin: 0 5em;
        /* height: 90%; */
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }
    ._1WIEHKxk2m0sDRibkHba7v {
        line-height: 3.4rem;
        font-size: 2rem;
    }
    ._1m_qIfSurTTNa4qUWWuNtd {
        font-size: 3rem;
        font-weight: 900;
    }

}

@media screen and (max-width:1000px) {
    ._303mcrGf1Ud3gpDfXL1qyk {
        height: 70vh;
        min-height: 160rem;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    ._1WIEHKxk2m0sDRibkHba7v {
        line-height: 4rem;
        font-size: 3rem;
    }
    .yX_6O3vHVfJ12ElwwU0eT {
        z-index: 1;
        position: absolute;
        height: 70vh;
        min-height: 160rem;
    }
    ._34zBB5bDMsjvtObZbnhCNQ {
        height: 70vh;
        min-height: 160rem;
        width: 100vw;
    }
    ._1vGZ0emNECf5uLsmTUXIoS {
        margin: 0rem 10em;
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    ._3aqvEU0dYoL_f6PnJRkVqv {
        overflow: visible !important;
        right: 200px;
    }
    ._303mcrGf1Ud3gpDfXL1qyk {
        height: auto;
        overflow: hidden;
        min-height: auto;
        background-blend-mode: multiply;
        -webkit-justify-content: center;
                justify-content: center;
    }
    ._1vGZ0emNECf5uLsmTUXIoS {
        margin: 15rem 0;
        width: 80%;
    }
    ._1WIEHKxk2m0sDRibkHba7v {
        line-height: 3rem;
        font-size: 2.4rem;
    }
    .yX_6O3vHVfJ12ElwwU0eT {
        display: hidden;
    }
}

@media screen and (max-width: 500px) {
    ._3aqvEU0dYoL_f6PnJRkVqv {
        overflow: visible !important;
        right: 200px;
    }
    ._303mcrGf1Ud3gpDfXL1qyk {
        height: auto;
        overflow: hidden;
        min-height: auto;
        background-blend-mode: multiply;
        -webkit-justify-content: center;
                justify-content: center; 
    }
    ._1vGZ0emNECf5uLsmTUXIoS {
        margin: 15rem 0;
        width: 80%;
    }
    ._1WIEHKxk2m0sDRibkHba7v {
        line-height: 3rem;
        font-size: 2.4rem;
    }
    ._303mcrGf1Ud3gpDfXL1qyk {
        height: 100%;
    }
    ._1m_qIfSurTTNa4qUWWuNtd {
        font-size: 2.5rem;
        font-weight: 900;
    }
    .yX_6O3vHVfJ12ElwwU0eT {
        display: hidden;
    }
}

nav {
  /* min-height: 80px; */
  /* position: fixed; */
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}

._18L5JIFo5iAnblubrQlixS {
  padding: 1rem;
}

._1Ubj8R5WBe91_7vgBD4qMu {
  height: 13em;
}

.logo-wrapper {
  /* background: rgba(0, 0, 255, 0.281); */
  /* position: absolute;
  top: 0;
  left: 0.2rem; */
  color: white;
  margin-left: 4rem;
  margin-right: auto;
}

.logoImageBlack {
  width: 200px;
}

.logoImageWhite {
  width: 200px;
}

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 5rem;
  height: 4.5rem;
  position: relative;
  margin: 5rem auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 0.6rem;
  width: 100%;
  background: black;
  border-radius: 0.9rem;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 1.2rem;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 2.4rem;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -0.3rem;
  left: 0.8rem;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 3.3rem;
  left: 0.8rem;
}

.hamburger {
  width: 3.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
  margin: 0.9rem 0;
}

.top-nav-hamburger {
  margin-left: 2rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.top-nav-wrapper {
  display: -webkit-flex;
  display: flex;
  /* position: fixed; */
  -webkit-flex-direction: column;
          flex-direction: column;
  top: 0;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.top-nav-primary {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 9rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.top-nav-right-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: 4rem;
}

.top-nav-right-list li {
  position: relative;
  color: white;
  padding: 0 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 0;
  font-family: LarsseitThin;
}
._3AUtZFFsAvUHG4fA3ovXGT {
    margin-top: 2rem;
    width: 6rem;
    height: 0.5rem;
    /* background: grey; */
    background: #474642;
}

._2nPy59wWoiyiBp94SC31IR {
    z-index: 5;
    margin-bottom: 5rem;
    margin-top: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    font-family: MonsterBold;
}

._2nPy59wWoiyiBp94SC31IR h1 {
    font-size: 3rem;
    text-align: center;
}

.TZJzxEms66a2x9mV0DFVs {
    color: black !important;
}



._2nPy59wWoiyiBp94SC31IR p {
    font-size: 2rem;
    text-align: center;
}

@media screen and (max-width:800px) {
    ._2nPy59wWoiyiBp94SC31IR {
        margin-top: 9rem;
    }
}
._2okS4-iqV9QxGtr2MN_mp7 {
    background: #800000;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-top: 5rem;
}

.AZT3520n1mMVI58XKPhSk {
    width: 120vw;
    height: 15rem;
    position: absolute;
    background: #800000;
    top: -0.1rem;
    left: -5%;
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
}

._2lQGwXk9fM_DRYuJGYFZhu {
    width: 120vw;
    height: 15rem;
    position: absolute;
    background: #800000;
    top: -0.1rem;
    right: -5%;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
}

._2VjlDacivBAvOYDHdPYJWT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 15rem;
}

._1x7PHFTRS3DOW-uCQW8ix8 {
    color: rgb(255, 255, 255);
    width: 80%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 0.034);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.452);
}

/* :local(.largeQuote) {
    color: rgba(255, 255, 255, 0.336);
    position: absolute;
    top: -4rem;
    left: 0;
    font-size: 15rem;
    font-family: QuoteFont;
} */

._2z5CKgvV_87q91IeaSJGKk {
    z-index: 2;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin: 0.4em 1em;
    /* font-style: italic; */
    font-size: 4rem;
    text-align: left;
    /* min-height: 6rem; */
}

/* :local(.authorWrapper) {
    display: flex;
    width: 92%;
    height: 5rem;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8%;
} */

.Ca2rVKz7M0DVSZRjp9jTn {
    width: 2rem;
    height: 0.2rem;
    background: rgb(248, 113, 113);
    margin-right: 3rem;
}

._3meyHaf77QHczYKcKW1Bsl {
    font-family: MonsterBold;
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.623);
}

._84AQYYcHKlaQLeCIMHKSj {
    margin-top: 2rem;
    width: 75%;
    margin-left: 5%;
    display: -webkit-flex;
    display: flex;
}

.FU0MGX6gKaL-XBLuSSZ2C {
    margin-left: 2rem;
    border-radius: 100%;
    height: 1rem;
    width: 1rem;
}

.eXrZAbTt5hRjLgxCqCRuZ {
    background: white;
}

._366RHLcAa8bc9k26_xeCtT {
    box-sizing: border-box;
    background: none;
    border-style: solid;
    border-width: 0.2rem;
    border-color: rgba(255, 255, 255, 0.658);
    
}

@media screen and (max-width: 1050px) {
    ._1x7PHFTRS3DOW-uCQW8ix8 {
        color: rgb(255, 255, 255);
        font-size: 1rem;
        display: -webkit-flex;
        display: flex;
        position: relative;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: row;
                flex-direction: row;
        border-radius: 1em;
        background-color: rgba(255, 255, 255, 0.034);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.452);
    }

    .Ca2rVKz7M0DVSZRjp9jTn {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    ._3meyHaf77QHczYKcKW1Bsl {
        font-size: 2.5rem;
    }
    .Ca2rVKz7M0DVSZRjp9jTn {
        width: 3rem;
        height: 0.4rem;
        margin-right: 2.5rem;
        
    }

    ._2z5CKgvV_87q91IeaSJGKk {
        font-size: 2rem;
        /* min-height: 6rem; */
    }
    ._2okS4-iqV9QxGtr2MN_mp7 {
        padding-top: 1rem;
    }
}

@media screen and (max-width: 420px) {
    ._1x7PHFTRS3DOW-uCQW8ix8 {
        color: rgb(255, 255, 255);
        display: -webkit-flex;
        display: flex;
        position: relative;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: row;
                flex-direction: row;
        border-radius: 1em;
        background-color: rgba(255, 255, 255, 0.034);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.452);
    }

    .Ca2rVKz7M0DVSZRjp9jTn {
        display: none;
    }
}
._3q6yZbM2p-XC_YUlWA5j_N {
    background: #800000;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-top: 1rem;
}

._39IJ_pGZohcKkMUAuRP7x5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    margin-top: 8rem;
    margin-bottom: 15rem;
}

._2i759Uhe8csdxJ4VzagYEb {
    width: 80%;
    height: 50%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

#_2b8BSaQLVAcKbneyF-iqxB {
    height: 9rem;
}

._11jkuh5rtfs9J_pG878vnq {
    margin-top: 1rem;
    margin: 0.5rem 0;
    height: 10rem;
    color: #800000;
}

@media screen and (max-width: 700px) {
    ._11jkuh5rtfs9J_pG878vnq {
        height: 10rem;
    }
}

@media screen and (max-width: 500px) {
    #_2b8BSaQLVAcKbneyF-iqxB {
        margin: 4rem 0;
        height: auto;
        width: 40vw;
    }
    ._11jkuh5rtfs9J_pG878vnq {
        margin: 4rem 0;
        height: auto;
        width: 40vw;
    }
    ._2i759Uhe8csdxJ4VzagYEb {
        margin: 5rem 0;
        height: 100%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    ._39IJ_pGZohcKkMUAuRP7x5 {
        padding: 0;
        margin-top: 1rem;
        margin-bottom: 5rem;
    }
}
._1JMhrtYNFBkc_79zd5uUQd {
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.ENMLS2L2be6I4AhYwUgYC {
    z-index: 3;
    margin: 15rem 0;
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

._2_WBy5eBjI-TZHgivBNhE {
    color: white;
    font-size: 4.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-bottom: 6rem;
}

._3V6bhJxDmMfLb-wuMkXmga {
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
}

.tzNFLZKa94f0isGrv2AWi {
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
    font-weight: bolder;
  }

  ._26xG9CF7eCBXyP7e7RKLJr {
    font-style: italic;
    font-weight: bolder;
    padding-right: 0.2rem;
    /* color:  #800000; */
 }

.E_5C8Xjkdil09zMMPXGpK {
    display: -webkit-flex;
    display: flex;
    z-index: 1;
    -webkit-flex: 1 1;
            flex: 1 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

._2ZwhXMJ4B9r90QOtH8My7k {
    font-style: italic;
    color: white;
    font-size: 2.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-top: 2rem;
}

.XLFETC2W6MMI4fZhPq-Uj {
    font-weight: lighter !important;
    font-family: MonsterBold;
}

._2ZKyQBP8g8L4B0I7YOU0MF {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 50vh;
    width: 100vw;
}

@media screen and (max-width:1000px) {}

@media screen and (max-width: 700px) {
    ._2iKHLcNsmqFcYgHtSWKSwv {
        width: 80%;
    }
    ._3V6bhJxDmMfLb-wuMkXmga {
        line-height: 3rem;
        font-size: 2.4rem;
    }
}
.agQnKQKhlD9G_2p3yAbF6 {
    display: -webkit-flex;
    display: flex;
    position: relative;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: rgb(240, 240, 240);
    padding: 4rem 0;
}

._1F9R9yBk0sYnFHfCX7bSb9 {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.KSUdQt0B0D3cux73uRnAr {
    /* align-self: flex-start; */
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

._3M2F5spl4id1Smhb77iPa7 {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100%;
    /* background: orange; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

._115Cg8V1IMVcGDUjcQsyzc {
    width: 70%;
}

._3Sq1NJTm0p8LL7pAXObRDf {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100%;
    /* background: pink; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

._1m-bhQN0r-C-frAGIPTTd8 {
    cursor: pointer;
    margin-top: 1rem;
    height: 3.5rem;
    width: 40%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-style: solid;
    border-width: 0.2rem;
    border-color: black;
    color: black;
    font-size: 1.6rem;
}

._1m-bhQN0r-C-frAGIPTTd8:hover {
    color: #800000;
    border-color: #800000;
}

._2tVNsPUuPRCukjW9uWPw-_ {
    margin-top: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

._2tVNsPUuPRCukjW9uWPw-_ span {
    margin: 0 1.5rem;
}

._3Sq1NJTm0p8LL7pAXObRDf {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

._2ttzc-MaFgECm5EgQPHk4j {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

._3NJ-NkcKv-dTRqhneID6H5 {
    width: 15rem;
    padding-bottom: 1rem;
    color: #800000;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    border-bottom-color: #800000;
    font-size: 2rem;
    margin-bottom: 2rem;
}

._3IBCpBaSbYqPEO_JR5gSBB {
    width: 15rem;
    height: 2rem;
    margin: 0.5rem 0;
    font-size: 1.5rem;
    color: black;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

._3IBCpBaSbYqPEO_JR5gSBB:hover {
    color: #800000;
}

@media screen and (max-width: 700px) {
    ._1F9R9yBk0sYnFHfCX7bSb9 {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .KSUdQt0B0D3cux73uRnAr {
        -webkit-align-self: center;
                align-self: center;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    ._3NJ-NkcKv-dTRqhneID6H5 {
        width: 35rem;
        padding-bottom: 1rem;
        border-bottom-width: 0.1rem;
        font-size: 4rem;
        margin-bottom: 3rem;
    }
    
    ._1m-bhQN0r-C-frAGIPTTd8 {
        margin-top: 1.5rem;
        height: 5.5rem;
        width: 80%;
        border-width: 0.3rem;
        font-size: 1.9rem;
    }
    ._2tVNsPUuPRCukjW9uWPw-_ {
        margin-top: 6rem;
    }
    ._2tVNsPUuPRCukjW9uWPw-_ span {
        margin: 0 2.5rem;
    }
    ._2ttzc-MaFgECm5EgQPHk4j {
        width: 100%;
    }
    ._3IBCpBaSbYqPEO_JR5gSBB {
        margin: 2rem 0;
        font-size: 2.5rem;
    }
}


._3nnZbpX_3hbfnBY6_nePLk {
    display: -webkit-flex;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background: rgba(0, 0, 0, 0.603);
    /* justify-content: center;
    align-items: center; */
}

._31qDt4kHfoyNSpCN_CXDba {
    position: relative;
    z-index: 9999;
}

._3owjUi6gyZaPVW9fiXfL6g {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    height: 100vh;
    width: 100vw;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background: rgba(0, 0, 0, 0.603);
    /* justify-content: center;
    align-items: center; */
}

._8gYgMarielkqS_CWOS0Y6{
    position: absolute;
    font-size: 100px;
    color: white;
    top: 20px;
    left: 20px;
 }

 .NtaJtn3nSwzJ4ByFEBpnI {
    position: absolute;
    z-index: 9998;
    height: 100vh;
    width: 100vw;
}
._2PszGdhZwZ-xUQCWupF9wb {
    position: relative;
    width: 30vw;
    padding: 2rem 0;
    /* height: 80vh; */
    background: white;
    border-radius: 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    overflow: auto;
    border: 1px solid  rgb(128,0,1);
}

.YpQoVveRjds_CEGzANx_F {
    width: 100%;
    padding: 3rem 0;
}

.YpQoVveRjds_CEGzANx_F h1 {
    font-size: 1.8rem;
    font-family: MonsterBold;
    color: rgb(36, 30, 61);
}

._3NAWgTYiSWct2x0EIznAgK {
    display: -webkit-flex;
    display: flex;
    width: 80%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 0;
    /* NEW */
    min-width: 0;
    /* NEW */
}

._1uEoiEBQzji3vxgnjTYgHP {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0.5rem 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

._1uEoiEBQzji3vxgnjTYgHP > label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-family: MonsterRegular;
    font-size: 1.6rem;
    width: 100%;
    text-align: left;
    color: rgb(36, 30, 61);
}

._2R2zSBhoq6s9bREB2IFEek {
    width: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex: 1 1;
            flex: 1 1;
}

._2aP4rJSNp9J9SiH0Q0mBre {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
}

._2cXwB5oRptBjJAP4Luqgly {
    border: none;
    border-bottom-color: rgb(36, 30, 61);
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    font-size: 1.6rem;
    color: rgb(36, 30, 61);
    border-radius: 0;
    background: none;
    outline: none;
    transition: all .15s ease-out;
}

._2cXwB5oRptBjJAP4Luqgly::-webkit-input-placeholder {
    color: rgb(167, 165, 161);
    font-size: 1.6rem;
}

._2cXwB5oRptBjJAP4Luqgly::-ms-input-placeholder {
    color: rgb(167, 165, 161);
    font-size: 1.6rem;
}

._2cXwB5oRptBjJAP4Luqgly::placeholder {
    color: rgb(167, 165, 161);
    font-size: 1.6rem;
}

._2cXwB5oRptBjJAP4Luqgly:focus {
    border: none;
    outline: none;
    border-bottom-color: rgba(0, 0, 0, 0.37);
    border-bottom-color: rgb(128,0,1);
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
}

.TQxxQBArV4xLwl3CSjpR0 {
    border: none;
    margin: .5rem 0;
    box-sizing: border-box;
    width: 100%;
    height: 10rem;
    font-size: 1.5rem;
    padding: 1rem;
    border-color: rgb(36, 30, 61);
    border-style: solid;
    border-width: 0.1rem;
    color: rgb(36, 30, 61);
    background: white;
    resize: vertical;
    outline: none;
}

.TQxxQBArV4xLwl3CSjpR0:focus {
    border: none;
    border-color: rgba(128,0,1, 0.37);
    border-style: solid;
    border-width: 0.1rem;
    transition: all .15s ease-out;
    box-sizing: border-box;
}


.TQxxQBArV4xLwl3CSjpR0::-webkit-input-placeholder {
    color: rgb(36, 30, 61);
}


.TQxxQBArV4xLwl3CSjpR0::-ms-input-placeholder {
    color: rgb(36, 30, 61);
}


.TQxxQBArV4xLwl3CSjpR0::placeholder {
    color: rgb(36, 30, 61);
}

._1IA1xepkXwMGaPXRX1-J-o {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 100%;
}

._1Lg-yPyz9RkBJuXheCH4gQ {
    margin: 1rem 0;
    padding: 0 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-style: solid;
    border-width: 0.1rem;
    border-color: aliceblue;
    background: rgb(128,0,1);
    color: white;
    height: 3rem;
    font-size: 1rem;
    font-family: MonsterRegular;
}

._1Lg-yPyz9RkBJuXheCH4gQ:disabled {
    opacity: 0.2;
    cursor: not-allowed;
}

._1Lg-yPyz9RkBJuXheCH4gQ:hover:enabled {
    border-color: #66FCF1;
    transition-delay: 5ms;
}

._31gracCTgD614HVPgM2ox- {
    cursor: pointer;
    color: #1F2833;
    position: absolute;
    top: 1rem;
    left: 1rem;
}

._27LfDNQDYVrVof128r_fFR {
    color: red;
    font-size: 1rem;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}


@media screen and (max-width: 1024px) {
    ._1Lg-yPyz9RkBJuXheCH4gQ {
        font-weight: 600;
    }
}


@media screen and (max-width: 800px) {
    ._2PszGdhZwZ-xUQCWupF9wb {
        width: 50vw;
        height: 50vw;
    }
    ._1Lg-yPyz9RkBJuXheCH4gQ {
        font-weight: 2rem;
    }
}

@media screen and (max-width: 500px) {
    ._2PszGdhZwZ-xUQCWupF9wb {
        width: 90vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .YpQoVveRjds_CEGzANx_F h1 {
        font-size: 2.4rem;
    }
    ._2cXwB5oRptBjJAP4Luqgly {
        height: 5rem;
        font-size: 3rem;
    }
    ._2cXwB5oRptBjJAP4Luqgly::-webkit-input-placeholder {
        color: rgb(167, 165, 161);
        font-size: 2.5rem;
    }
    ._2cXwB5oRptBjJAP4Luqgly::-ms-input-placeholder {
        color: rgb(167, 165, 161);
        font-size: 2.5rem;
    }
    ._2cXwB5oRptBjJAP4Luqgly::placeholder {
        color: rgb(167, 165, 161);
        font-size: 2.5rem;
    }
    ._1uEoiEBQzji3vxgnjTYgHP > label {
        margin-bottom: 0.5rem;
        font-size: 2rem;
    }
    ._1uEoiEBQzji3vxgnjTYgHP {
        margin: 2rem 0;
    }
    ._1Lg-yPyz9RkBJuXheCH4gQ {
        margin: 1rem 0;
        padding: 0 3rem;
        border-width: 1px;
        height: 5rem;
        font-size: 2.4rem;
    }
    .TQxxQBArV4xLwl3CSjpR0 {
        margin: .5rem 0;
        height: 30rem;
        font-size: 3rem;
        padding: 2rem;
        border-width: 1px;
    }
}
._205K-YJKBLxcBXi4xKVs4f {
    width: 100%;
    height: 100vh;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.f1sNIMUYaQL4ITFwD5q9b {
    z-index: 3;
    margin: 15rem 0;
    margin-right: 5%;
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.BZbw47vH8FHdM4iWfa8Uy {
    color: white;
    font-size: 4.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-bottom: 6rem;
    
}

._1LrNWZITCZQN21LSukFFGa {
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
}

._3DdVoVo4AYSCQer0uEtyc- {
    display: -webkit-flex;
    display: flex;
    z-index: 1;
    -webkit-flex: 1 1;
            flex: 1 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

._32M61xLe946eE9fvgEgUhY {
    font-style: italic;
    color: white;
    font-size: 2.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-top: 2rem;
}

.qgLsnji92Qfic3NISJB2u {
    font-style: italic;
    font-weight: bolder;
    padding-right: 0.2rem;
    /* color:  #800000; */
 }

._2fZnADq3Onv5eDaxAG0uSx {
    font-weight: lighter !important;
    font-family: MonsterBold;
}

._4Em2luDC9qyBV2My2BTLV {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 50vh;
    width: 100vw;
}

@media screen and (max-width: 1000px) {
    ._205K-YJKBLxcBXi4xKVs4f {
        height: auto;
    }
    .LxcwObC0um6qLm02oKsqS {
        width: 80%;
    }
    .BZbw47vH8FHdM4iWfa8Uy {
        font-size: 5rem;
    }
    ._32M61xLe946eE9fvgEgUhY {
        font-size: 4rem;
        margin-top: 4rem;;
    }
    ._1LrNWZITCZQN21LSukFFGa {
        line-height: 5rem;
        font-size: 2rem;
    }
}

@media screen and (max-width: 700px) {
    ._205K-YJKBLxcBXi4xKVs4f {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .f1sNIMUYaQL4ITFwD5q9b {
        width: 80%;
    }
    .LxcwObC0um6qLm02oKsqS {
        width: 80%;
    }
    ._32M61xLe946eE9fvgEgUhY {
        font-size: 5rem;
    }
    ._1LrNWZITCZQN21LSukFFGa {
        line-height: 5rem;
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 360px) {
    .LxcwObC0um6qLm02oKsqS {
        width: 80%;
    }
    ._32M61xLe946eE9fvgEgUhY {
        font-size: 5rem;
    }
    ._1LrNWZITCZQN21LSukFFGa {
        line-height: 3.5rem;
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 320px) {
    .LxcwObC0um6qLm02oKsqS {
        width: 80%;
    }
    ._32M61xLe946eE9fvgEgUhY {
        font-size: 5rem;
    }
    ._1LrNWZITCZQN21LSukFFGa {
        line-height: 2.5rem;
        font-size: 2.2rem;
    }
}
._1oa8E2Nm3xYMEbVZrJO96N {
    position: relative;
    height: 90vh;
    min-height: 100rem;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    background-blend-mode: multiply;
    background-color: black; 
}

._3rYR37pz_qwtpGwsQ69HLU {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    background-color: black; 
}

._3-YOXqBgh_7i-bD8nlmma {
    min-height: 70rem;
    width: 100vw;
}

.-_dBeU2C4BY6K9qrOMv3l {
    width: 100vw;
    background: black;
}

.ERCMwCrR7QSM3r9BIxUFZ {
    position: relative;
    z-index: 2;
    width: 40%;
    margin: 0 5em;
    height: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    /* background: green; */
}

._3scyMX-u5nc8jJGKgAvP5A {
    margin-bottom: 3.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    color: white;
    font-size: 4.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-bottom: 6rem;
}

.vdkadKxheLvonzCcXjC4y {
    margin-bottom: 3.5rem;
    display: -webkit-flex;
    display: flex;
    font-size: 2.8rem;
    line-height: 5.2rem;
    font-weight: 700;
    color: white;
}

._195HZ9MDLy3hL6nsb4WUKc {
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
    line-height: 3.2rem;
    letter-spacing: 0rem;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-align: left;
    padding-right: 1.5rem;
}

._2rQMA8VMmrciU2Gtr6JWxM {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.gQJP1Vz_-BuzbUxuAoiZw {
    height: 18em;
  }

#_2z2WYu_y2abDa-4FTrUCfw {
    cursor: pointer;
    margin-bottom: 1.5rem;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.8rem;
    border-style: solid;
    height: 6.5rem;
    padding: 0rem 2rem;
    border-width: 0.2rem;
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    outline: none;
    background-color: #0f0f0f;
}

.vJCyPcQfwIxYRBNGuu4Em {
    color: #ffffff;
    /* margin: 0 0.5em; */
}

._3XuR9VMq47fbBtFLd3j19E {
    font-style: italic;
    font-weight: bolder;
    padding-right: 0.2rem;
    /* color:  #800000; */
 }

#_2z2WYu_y2abDa-4FTrUCfw:hover {
    border-color: rgb(8, 165, 154);
    color: rgb(255, 255, 255);
    background-color: #0f0f0f;
}

@media screen and (max-width:1000px) {
    ._1oa8E2Nm3xYMEbVZrJO96N {
        height: 70vh;
        min-height: 160rem;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    ._195HZ9MDLy3hL6nsb4WUKc {
        line-height: 4rem;
        font-size: 3rem;
    }
    ._3rYR37pz_qwtpGwsQ69HLU {
        /* overflow: scroll; */
        z-index: 1;
        position: absolute;
        height: 70vh;
        min-height: 160rem;
    }
    ._3-YOXqBgh_7i-bD8nlmma {
        height: 70vh;
        min-height: 160rem;
        width: 100vw;
    }
    .ERCMwCrR7QSM3r9BIxUFZ {
        margin: 0rem 10em;
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .-_dBeU2C4BY6K9qrOMv3l {
        overflow: visible !important;
        right: 200px;
    }
    ._1oa8E2Nm3xYMEbVZrJO96N {
        height: auto;
        overflow: hidden;
        min-height: auto;
        background-blend-mode: multiply;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .ERCMwCrR7QSM3r9BIxUFZ {
        margin: 15rem 0;
        width: 80%;
    }
    ._195HZ9MDLy3hL6nsb4WUKc {
        line-height: 3rem;
        font-size: 2.4rem;
    }
    ._3rYR37pz_qwtpGwsQ69HLU {
        display: hidden;
        /* overflow: hidden;
        display: flex;
        z-index: 1;
        position: absolute;
        height: 100%;
        min-height: 100%; */
    }
}

@media screen and (max-width: 500px) {
    .-_dBeU2C4BY6K9qrOMv3l {
        overflow: visible !important;
        left: 5px;
    }
    ._1oa8E2Nm3xYMEbVZrJO96N {
        height: auto;
        overflow: hidden;
        min-height: auto;
        background-blend-mode: multiply;
        -webkit-justify-content: center;
                justify-content: center;
        
    }
    .ERCMwCrR7QSM3r9BIxUFZ {
        margin: 15rem 0;
        width: 80%;
    }
    ._195HZ9MDLy3hL6nsb4WUKc {
        line-height: 3rem;
        font-size: 2.4rem;
    }
    ._1oa8E2Nm3xYMEbVZrJO96N {
        height: 100%;
        /* min-height: 10; */
    }
    ._3rYR37pz_qwtpGwsQ69HLU {
        display: hidden;
        /* overflow: hidden;
        display: flex;
        z-index: 1;
        position: absolute;
        height: 100%;
        min-height: 100%; */
    }
}

/* @media screen and (.max-widt) {

} */

.hxh-CmTRH4V5V5tOc1kdi {
    display: -webkit-flex;
    display: flex;
}

._suO77RJoGgEcJ3cU_M3s {
    font-size: 2.3rem;
    color: rgb(128,0,1);
    margin-right: 0.3rem;
}

._2d3KhuJaQwlBJaJEpYbaNj {
    font-size: 1.7rem;
    color: rgb(68,67,65);
    font-weight: 600;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@media screen and (max-width: 700px) {
    .hxh-CmTRH4V5V5tOc1kdi {
        padding: 0 0 3rem;
    }
    ._suO77RJoGgEcJ3cU_M3s {
        font-size: 3.5rem;
        color: rgb(128,0,1);
        margin-right: 0.5rem;
    }
    
    ._2d3KhuJaQwlBJaJEpYbaNj {
        font-size: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .hxh-CmTRH4V5V5tOc1kdi {
        padding: 0 0 3rem;
    }
    ._suO77RJoGgEcJ3cU_M3s {
        font-size: 1.8rem;
        color: rgb(128,0,1);
        margin-right: 0.5rem;
    }
    
    ._2d3KhuJaQwlBJaJEpYbaNj {
        font-size: 1.4rem;
    }
}

._3sj0D6P8J0RL-EG81fdKwz {
    height: 100vh;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

._20yintZzvQf5qV-MYJnsyn {
    width: 100vw;
    /* height: 100vh; */
    overflow: hidden;
}

/*
  COLOR:
  CYAN: #66FCF1
  DARKBLUE: #1F2833
  LIGHTGREY: #C5C6C7
  DARKCYAN: #45A29E
*/

@font-face {
  font-family: MonsterRegular;
  src: url(/static/media/Montserrat-Regular.9c460951.ttf);
}

@font-face {
  font-family: QuoteFont;
  src: url(/static/media/BalooChettan-Regular.5e48b58c.ttf);
}

@font-face {
  font-family: MonsterLight;
  src: url(/static/media/Montserrat-Light.100b38fa.ttf);
}

@font-face {
  font-family: MonsterBold;
  src: url(/static/media/Montserrat-Bold.88932dad.ttf);
}

.App {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* overflow-y: scroll; */
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100) !important; */
  /* display: -webkit-box;      OLD - iOS 6-, Safari 3.1-6 */
  /* display: -moz-box;         OLD - Firefox 19- (buggy but mostly works) */
  /* display: -ms-flexbox;      TWEENER - IE 10 */
  /* display: -webkit-flex;     NEW - Chrome */
  /* display:flex; */
  /* flex-direction: column; */
  font-family: MonsterRegular;
  background: white;
  text-align: center;
}

._2_f4Y27TYFrPuly8ruLpco {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.404);
}
