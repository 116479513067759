:local(.contactWrapper) {
    position: relative;
    width: 30vw;
    padding: 2rem 0;
    /* height: 80vh; */
    background: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    border: 1px solid  rgb(128,0,1);
}

:local(.header) {
    width: 100%;
    padding: 3rem 0;
}

:local(.header h1) {
    font-size: 1.8rem;
    font-family: MonsterBold;
    color: rgb(36, 30, 61);
}

:local(.contentWrapper) {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 0;
    /* NEW */
    min-width: 0;
    /* NEW */
}

:local(.inputGroup) {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    justify-content: center;
    align-items: center;
    position: relative;
}

:local(.inputGroup > label) {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-family: MonsterRegular;
    font-size: 1.6rem;
    width: 100%;
    text-align: left;
    color: rgb(36, 30, 61);
}

:local(.flexWrapper) {
    width: 100%;
    flex-grow: 1;
    flex: 1;
}

:local(.form) {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
}

:local(.input) {
    border: none;
    border-bottom-color: rgb(36, 30, 61);
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    font-size: 1.6rem;
    color: rgb(36, 30, 61);
    border-radius: 0;
    background: none;
    outline: none;
    transition: all .15s ease-out;
}

:local(.input::placeholder) {
    color: rgb(167, 165, 161);
    font-size: 1.6rem;
}

:local(.input:focus) {
    border: none;
    outline: none;
    border-bottom-color: rgba(0, 0, 0, 0.37);
    border-bottom-color: rgb(128,0,1);
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
}

:local(.textArea) {
    border: none;
    margin: .5rem 0;
    box-sizing: border-box;
    width: 100%;
    height: 10rem;
    font-size: 1.5rem;
    padding: 1rem;
    border-color: rgb(36, 30, 61);
    border-style: solid;
    border-width: 0.1rem;
    color: rgb(36, 30, 61);
    background: white;
    resize: vertical;
    outline: none;
}

:local(.textArea:focus) {
    border: none;
    border-color: rgba(128,0,1, 0.37);
    border-style: solid;
    border-width: 0.1rem;
    transition: all .15s ease-out;
    box-sizing: border-box;
}


:local(.textArea::placeholder) {
    color: rgb(36, 30, 61);
}

:local(.buttonWrapper) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

:local(.submitButton) {
    margin: 1rem 0;
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0.1rem;
    border-color: aliceblue;
    background: rgb(128,0,1);
    color: white;
    height: 3rem;
    font-size: 1rem;
    font-family: MonsterRegular;
}

:local(.submitButton:disabled) {
    opacity: 0.2;
    cursor: not-allowed;
}

:local(.submitButton:hover:enabled) {
    border-color: #66FCF1;
    transition-delay: 5ms;
}

:local(.x) {
    cursor: pointer;
    color: #1F2833;
    position: absolute;
    top: 1rem;
    left: 1rem;
}

:local(.errorLabel) {
    color: red;
    font-size: 1rem;
    justify-content: flex-start;
}


@media screen and (max-width: 1024px) {
    :local(.submitButton) {
        font-weight: 600;
    }
}


@media screen and (max-width: 800px) {
    :local(.contactWrapper) {
        width: 50vw;
        height: 50vw;
    }
    :local(.submitButton) {
        font-weight: 2rem;
    }
}

@media screen and (max-width: 500px) {
    :local(.contactWrapper) {
        width: 90vw;
        height: fit-content;
    }
    :local(.header h1) {
        font-size: 2.4rem;
    }
    :local(.input) {
        height: 5rem;
        font-size: 3rem;
    }
    :local(.input::placeholder) {
        color: rgb(167, 165, 161);
        font-size: 2.5rem;
    }
    :local(.inputGroup > label) {
        margin-bottom: 0.5rem;
        font-size: 2rem;
    }
    :local(.inputGroup) {
        margin: 2rem 0;
    }
    :local(.submitButton) {
        margin: 1rem 0;
        padding: 0 3rem;
        border-width: 1px;
        height: 5rem;
        font-size: 2.4rem;
    }
    :local(.textArea) {
        margin: .5rem 0;
        height: 30rem;
        font-size: 3rem;
        padding: 2rem;
        border-width: 1px;
    }
}