:local(.testGlobalWrapper) {
    background: #800000;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 5rem;
}

:local(.header) {
    width: 120vw;
    height: 15rem;
    position: absolute;
    background: #800000;
    top: -0.1rem;
    left: -5%;
    transform: rotate(5deg);
}

:local(.headerRight) {
    width: 120vw;
    height: 15rem;
    position: absolute;
    background: #800000;
    top: -0.1rem;
    right: -5%;
    transform: rotate(-5deg);
}

:local(.contentWrapper) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 15rem;
}

:local(.itemGroup) {
    color: rgb(255, 255, 255);
    width: 80%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 0.034);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.452);
}

/* :local(.largeQuote) {
    color: rgba(255, 255, 255, 0.336);
    position: absolute;
    top: -4rem;
    left: 0;
    font-size: 15rem;
    font-family: QuoteFont;
} */

:local(.itemText) {
    z-index: 2;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin: 0.4em 1em;
    /* font-style: italic; */
    font-size: 4rem;
    text-align: left;
    /* min-height: 6rem; */
}

/* :local(.authorWrapper) {
    display: flex;
    width: 92%;
    height: 5rem;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8%;
} */

:local(.customDash) {
    width: 2rem;
    height: 0.2rem;
    background: rgb(248, 113, 113);
    margin-right: 3rem;
}

:local(.author) {
    font-family: MonsterBold;
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.623);
}

:local(.dots) {
    margin-top: 2rem;
    width: 75%;
    margin-left: 5%;
    display: flex;
}

:local(.singleDot) {
    margin-left: 2rem;
    border-radius: 100%;
    height: 1rem;
    width: 1rem;
}

:local(.white) {
    background: white;
}

:local(.grey) {
    box-sizing: border-box;
    background: none;
    border-style: solid;
    border-width: 0.2rem;
    border-color: rgba(255, 255, 255, 0.658);
    
}

@media screen and (max-width: 1050px) {
    :local(.itemGroup) {
        color: rgb(255, 255, 255);
        font-size: 1rem;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 1em;
        background-color: rgba(255, 255, 255, 0.034);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.452);
    }

    :local(.customDash) {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    :local(.author) {
        font-size: 2.5rem;
    }
    :local(.customDash) {
        width: 3rem;
        height: 0.4rem;
        margin-right: 2.5rem;
        
    }

    :local(.itemText) {
        font-size: 2rem;
        /* min-height: 6rem; */
    }
    :local(.testGlobalWrapper) {
        padding-top: 1rem;
    }
}

@media screen and (max-width: 420px) {
    :local(.itemGroup) {
        color: rgb(255, 255, 255);
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 1em;
        background-color: rgba(255, 255, 255, 0.034);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.452);
    }

    :local(.customDash) {
        display: none;
    }
}