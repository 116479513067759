nav {
  /* min-height: 80px; */
  /* position: fixed; */
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}

:local(.phoneWrapper) {
  padding: 1rem;
}

:local(.logo) {
  height: 13em;
}

.logo-wrapper {
  /* background: rgba(0, 0, 255, 0.281); */
  /* position: absolute;
  top: 0;
  left: 0.2rem; */
  color: white;
  margin-left: 4rem;
  margin-right: auto;
}

.logoImageBlack {
  width: 200px;
}

.logoImageWhite {
  width: 200px;
}

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 5rem;
  height: 4.5rem;
  position: relative;
  margin: 5rem auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 0.6rem;
  width: 100%;
  background: black;
  border-radius: 0.9rem;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 1.2rem;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 2.4rem;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -0.3rem;
  left: 0.8rem;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 3.3rem;
  left: 0.8rem;
}

.hamburger {
  width: 3.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
  margin: 0.9rem 0;
}

.top-nav-hamburger {
  margin-left: 2rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.top-nav-wrapper {
  display: flex;
  /* position: fixed; */
  flex-direction: column;
  top: 0;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.top-nav-primary {
  display: flex;
  width: 100%;
  height: 9rem;
  align-items: center;
  justify-content: flex-end;
}

.top-nav-right-list {
  display: flex;
  justify-content: space-between;
  margin-right: 4rem;
}

.top-nav-right-list li {
  position: relative;
  color: white;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  font-family: LarsseitThin;
}