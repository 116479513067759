html {
    font-size: 10px;
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 8px;
    }
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 7px;
    }
}

@media screen and (max-width: 850px) {
    html {
        font-size: 6px;
    }
}

@media screen and (max-width: 500px) {
    html {
        font-size: 5px;
    }
}