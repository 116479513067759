:local(.globalWrapper) {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

:local(.contentWrapper) {
    z-index: 3;
    margin: 15rem 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

:local(.contentHeader) {
    color: white;
    font-size: 4.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-bottom: 6rem;
}

:local(.contentCopy) {
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
}

:local(.bold) {
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
    font-weight: bolder;
  }

  :local(.brand) {
    font-style: italic;
    font-weight: bolder;
    padding-right: 0.2rem;
    /* color:  #800000; */
 }

:local(.parallaxContainer) {
    display: flex;
    z-index: 1;
    flex: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

:local(.subHeader) {
    font-style: italic;
    color: white;
    font-size: 2.5rem;
    font-family: MonsterBold;
    text-align: left;
    margin-top: 2rem;
}

:local(.subHeaderThin) {
    font-weight: lighter !important;
    font-family: MonsterBold;
}

:local(.parallaxContent) {
    flex: 1;
    height: 50vh;
    width: 100vw;
}

@media screen and (max-width:1000px) {}

@media screen and (max-width: 700px) {
    :local(.infoSection) {
        width: 80%;
    }
    :local(.contentCopy) {
        line-height: 3rem;
        font-size: 2.4rem;
    }
}