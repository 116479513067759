:local(.mainWrapper) {
    position: relative;
    height: 90vh;
    min-height: 130rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-blend-mode: multiply;
    background-color: black; 
    overflow: hidden;
}

:local(.parallaxContainer) {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: black; 
}

:local(.parallaxContent) {
    min-height: 130rem;
    width: 100vw;
    display: flex;
    align-items: center;
}

:local(.parallaxImage) {
    width: 100vw;
    background: black;
}

:local(.infoSection) {
    position: relative;
    z-index: 2;
    width: 60%;
    margin: 0 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

:local(.head) {
    margin-bottom: 3.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 3.8rem;
    line-height: 5.2rem;
    font-weight: 900;
    color: rgb(192, 14, 14);
}

:local(.about) {
    margin-bottom: 3.5rem;
    display: flex;
    font-size: 2.8rem;
    line-height: 5.2rem;
    font-weight: 700;
    color: white;
}

:local(.copy) {
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
    line-height: 3.2rem;
    letter-spacing: 0rem;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-align: left;
}


:local(.header) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* background: blue; */
}

:local(.brand) {
   font-style: italic;
   font-weight: bolder;
   padding-right: 0.2rem;
}

:local(.hostName) {
    font-size: 2rem;
    font-weight: 400;
}

:local(.logo) {
    height: 18em;
  }

:local(#learnButton) {
    cursor: pointer;
    margin-bottom: 1.5rem;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    border-style: solid;
    height: 6.5rem;
    padding: 0rem 2rem;
    border-width: 0.2rem;
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    outline: none;
    background-color: #0f0f0f;
}

:local(.brandName) {
    color: #ffffff;
}

:local(#learnButton:hover) {
    border-color: rgb(8, 165, 154);
    color: rgb(255, 255, 255);
    background-color: #0f0f0f;
}

/* @media screen and (min-width: 1301px) {
    :local(.mainWrapper){
        min-height: 80rem;
    }
} */

@media screen and (min-width: 1200px) {
    :local(.mainWrapper) {
        display: flex;
        align-items: center;
        min-height: 120rem;
    }
    :local(.infoSection) {
        position: relative;
        z-index: 2;
        width: 55%;
        margin: 0 5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 1199px) {

    :local(.mainWrapper) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 130rem;
        
    }
    :local(.infoSection) {
        position: relative;
        z-index: 2;
        width: 80%;
        margin: 0 5em;
        /* height: 90%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    :local(.copy) {
        line-height: 3.4rem;
        font-size: 2rem;
    }
    :local(.hostName) {
        font-size: 3rem;
        font-weight: 900;
    }

}

@media screen and (max-width:1000px) {
    :local(.mainWrapper) {
        height: 70vh;
        min-height: 160rem;
        justify-content: flex-start;
    }
    :local(.copy) {
        line-height: 4rem;
        font-size: 3rem;
    }
    :local(.parallaxContainer) {
        z-index: 1;
        position: absolute;
        height: 70vh;
        min-height: 160rem;
    }
    :local(.parallaxContent) {
        height: 70vh;
        min-height: 160rem;
        width: 100vw;
    }
    :local(.infoSection) {
        margin: 0rem 10em;
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    :local(.parallaxImage) {
        overflow: visible !important;
        right: 200px;
    }
    :local(.mainWrapper) {
        height: auto;
        overflow: hidden;
        min-height: auto;
        background-blend-mode: multiply;
        justify-content: center;
    }
    :local(.infoSection) {
        margin: 15rem 0;
        width: 80%;
    }
    :local(.copy) {
        line-height: 3rem;
        font-size: 2.4rem;
    }
    :local(.parallaxContainer) {
        display: hidden;
    }
}

@media screen and (max-width: 500px) {
    :local(.parallaxImage) {
        overflow: visible !important;
        right: 200px;
    }
    :local(.mainWrapper) {
        height: auto;
        overflow: hidden;
        min-height: auto;
        background-blend-mode: multiply;
        justify-content: center; 
    }
    :local(.infoSection) {
        margin: 15rem 0;
        width: 80%;
    }
    :local(.copy) {
        line-height: 3rem;
        font-size: 2.4rem;
    }
    :local(.mainWrapper) {
        height: 100%;
    }
    :local(.hostName) {
        font-size: 2.5rem;
        font-weight: 900;
    }
    :local(.parallaxContainer) {
        display: hidden;
    }
}
