
:local(.wrapper) {
    display: flex;
}

:local(.phoneIcon) {
    font-size: 2.3rem;
    color: rgb(128,0,1);
    margin-right: 0.3rem;
}

:local(.phone) {
    font-size: 1.7rem;
    color: rgb(68,67,65);
    font-weight: 600;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@media screen and (max-width: 700px) {
    :local(.wrapper) {
        padding: 0 0 3rem;
    }
    :local(.phoneIcon) {
        font-size: 3.5rem;
        color: rgb(128,0,1);
        margin-right: 0.5rem;
    }
    
    :local(.phone) {
        font-size: 3rem;
    }
}

@media screen and (max-width: 500px) {
    :local(.wrapper) {
        padding: 0 0 3rem;
    }
    :local(.phoneIcon) {
        font-size: 1.8rem;
        color: rgb(128,0,1);
        margin-right: 0.5rem;
    }
    
    :local(.phone) {
        font-size: 1.4rem;
    }
}
