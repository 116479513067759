:local(.footerWrapper) {
    display: flex;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(240, 240, 240);
    padding: 4rem 0;
}

:local(.contentWrapper) {
    width: 90%;
    display: flex;
    align-items: center;
}

:local(.contactWrapper) {
    /* align-self: flex-start; */
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

:local(.logoWrapper) {
    flex: 1;
    height: 100%;
    /* background: orange; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}

:local(.logo) {
    width: 70%;
}

:local(.siteMapWrapper) {
    flex: 1;
    height: 100%;
    /* background: pink; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

:local(.contactButton) {
    cursor: pointer;
    margin-top: 1rem;
    height: 3.5rem;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0.2rem;
    border-color: black;
    color: black;
    font-size: 1.6rem;
}

:local(.contactButton:hover) {
    color: #800000;
    border-color: #800000;
}

:local(.socialMediaIcons) {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

:local(.socialMediaIcons span) {
    margin: 0 1.5rem;
}

:local(.siteMapWrapper) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

:local(.siteMapList) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

:local(.header) {
    width: 15rem;
    padding-bottom: 1rem;
    color: #800000;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    border-bottom-color: #800000;
    font-size: 2rem;
    margin-bottom: 2rem;
}

:local(.siteMapItem) {
    width: 15rem;
    height: 2rem;
    margin: 0.5rem 0;
    font-size: 1.5rem;
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

:local(.siteMapItem:hover) {
    color: #800000;
}

@media screen and (max-width: 700px) {
    :local(.contentWrapper) {
        flex-direction: column;
        align-items: center;
    }
    :local(.contactWrapper) {
        align-self: center;
        justify-content: flex-start;
    }
    :local(.header) {
        width: 35rem;
        padding-bottom: 1rem;
        border-bottom-width: 0.1rem;
        font-size: 4rem;
        margin-bottom: 3rem;
    }
    
    :local(.contactButton) {
        margin-top: 1.5rem;
        height: 5.5rem;
        width: 80%;
        border-width: 0.3rem;
        font-size: 1.9rem;
    }
    :local(.socialMediaIcons) {
        margin-top: 6rem;
    }
    :local(.socialMediaIcons span) {
        margin: 0 2.5rem;
    }
    :local(.siteMapList) {
        width: 100%;
    }
    :local(.siteMapItem) {
        margin: 2rem 0;
        font-size: 2.5rem;
    }
}

