:local(.mainWrapper) {
    position: relative;
    flex-direction: column;
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

:local(.logo) {
    margin-top: 1.5em;
    background: white;
    border-radius: 1em;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.664);
    width: 30vw;
}

:local(.subHeader) {
    color: #800000;
    font-size: 2.4rem;
    font-style: italic;
    font-weight: 700;
}

:local(.mainWrapper h1) {
    color: white;
    font-weight: 700;
    font-size: 5rem;
    margin-bottom: 2rem;
}

:local(.videoWrapper) {
    display: flex;
    height: 50vw;
    max-height: 90vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    /* background: rgb(20, 20, 20); */
    background:  #800000;
    box-shadow: 0 0.5rem 2.5rem 0 black;
}

:local(.playIcon) {
    font-size: 20rem;
    color: rgb(161, 161, 161);
}

:local(.video) {
    width: 100%;
    height: 100%;
}