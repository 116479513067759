:local(.loadingWrapper) {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

:local(.homeWrapper) {
    width: 100vw;
    /* height: 100vh; */
    overflow: hidden;
}