/*
  COLOR:
  CYAN: #66FCF1
  DARKBLUE: #1F2833
  LIGHTGREY: #C5C6C7
  DARKCYAN: #45A29E
*/

@font-face {
  font-family: MonsterRegular;
  src: url('./Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: QuoteFont;
  src: url('./BalooChettan-Regular.ttf');
}

@font-face {
  font-family: MonsterLight;
  src: url('./Montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: MonsterBold;
  src: url('./Montserrat/Montserrat-Bold.ttf');
}

.App {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* overflow-y: scroll; */
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100) !important; */
  /* display: -webkit-box;      OLD - iOS 6-, Safari 3.1-6 */
  /* display: -moz-box;         OLD - Firefox 19- (buggy but mostly works) */
  /* display: -ms-flexbox;      TWEENER - IE 10 */
  /* display: -webkit-flex;     NEW - Chrome */
  /* display:flex; */
  /* flex-direction: column; */
  font-family: MonsterRegular;
  background: white;
  text-align: center;
}

:local(.clickable) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.404);
}