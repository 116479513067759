:local(.video) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin: 0px 1.5%;
    margin-bottom: 40px;
    box-sizing: border-box;
}

:local(.floatingTitle) {
    background: #800000;
    padding: 0.8rem 2rem;
    color: white;
    position: absolute;
    font-size: 1.5rem;
    top: 1rem;
    left: 1rem;
    border-radius: 5px;
}

:local(.playButtonWrapper) {
    background: rgba(0, 0, 0, 0.562);
    border-radius: 10px;
    padding: 0.5rem 2rem;
    cursor: pointer;
}

:local(.playIcon) {
    cursor: pointer;
    color: rgb(127, 127, 127);
    font-size: 5rem;
}

@media screen and (max-width: 750px) {
    :local(.video) {
        width: 90%;
    }
    :local(.floatingTitle) {
        font-size: 2.5rem;
    }
    :local(.playButtonWrapper) {
        border-radius: 5px;
        padding: 3rem 6rem;
    }
    :local(.playIcon) {
        font-size: 10rem;
    }
}

@media screen and (max-width: 450px) {
    :local(.playButtonWrapper) {
        border-radius: 5px;
        padding: 0.8rem 3rem;
    }
    :local(.playIcon) {
        color: white;
        font-size: 8rem;
    }
}