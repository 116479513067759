:local(.mainWrapper) {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.603);
    /* justify-content: center;
    align-items: center; */
}

:local(.children) {
    position: relative;
    z-index: 9999;
}

:local(.mainWrapperBott) {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.603);
    /* justify-content: center;
    align-items: center; */
}

:local(.closeButton){
    position: absolute;
    font-size: 100px;
    color: white;
    top: 20px;
    left: 20px;
 }

 :local(.clickable) {
    position: absolute;
    z-index: 9998;
    height: 100vh;
    width: 100vw;
}