:local(.coloredSpacer) {
    margin-top: 2rem;
    width: 6rem;
    height: 0.5rem;
    /* background: grey; */
    background: #474642;
}

:local(.copyWrapper) {
    z-index: 5;
    margin-bottom: 5rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: MonsterBold;
}

:local(.copyWrapper h1) {
    font-size: 3rem;
    text-align: center;
}

:local(.alternateHeader) {
    color: black !important;
}



:local(.copyWrapper p) {
    font-size: 2rem;
    text-align: center;
}

@media screen and (max-width:800px) {
    :local(.copyWrapper) {
        margin-top: 9rem;
    }
}