:local(.testGlobalWrapper) {
    background: #800000;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1rem;
}

:local(.contentWrapper) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 8rem;
    margin-bottom: 15rem;
}

:local(.partners) {
    width: 80%;
    height: 50%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
}

:local(#foxLogo) {
    height: 9rem;
}

:local(.partnerLogo) {
    margin-top: 1rem;
    margin: 0.5rem 0;
    height: 10rem;
    color: #800000;
}

@media screen and (max-width: 700px) {
    :local(.partnerLogo) {
        height: 10rem;
    }
}

@media screen and (max-width: 500px) {
    :local(#foxLogo) {
        margin: 4rem 0;
        height: auto;
        width: 40vw;
    }
    :local(.partnerLogo) {
        margin: 4rem 0;
        height: auto;
        width: 40vw;
    }
    :local(.partners) {
        margin: 5rem 0;
        height: 100%;
        align-items: center;
        flex-direction: column;
    }
    :local(.contentWrapper) {
        padding: 0;
        margin-top: 1rem;
        margin-bottom: 5rem;
    }
}